/**
 * CookieBanner provides for the selection of cookie preferences.
 */
import React from 'react';
import useCookieBanner from './useCookieBanner';
import {onClickA11y} from '../util/pagetools';
import { cookiebanner, btn } from '../styles/cookiebanner.module.scss';

export default function CookieBanner(props) {
  const cb = useCookieBanner();
  const parentClickAttributes = onClickA11y(cb.hide);
  const visStyle = (cb.doDisplay() ? 'visx' : 'hidx');

  return (
    <div {...parentClickAttributes} className={`white-on-pary ${cookiebanner} ${visStyle}`}>
      <div className="fg-1 p-8">
        <span>By clicking "Accept", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. </span>
        <a href="https://www.wcgclinical.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy&nbsp;Policy</a>
      </div>
      <div className="flx flx-ctr">
        <button className={`std-btn btn-border ${btn} dark white-on-dark`} onClick={cb.decline}>Decline</button>
        <button className={`std-btn btn-border ${btn} white-on-dark`} onClick={cb.confirm}>Accept</button>
      </div>
    </div>
  );
};
